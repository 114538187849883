import React from "react";
import { Card } from "material-ui/Card";
import s from "./CustomAutocomplete.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Loader from "./Loader";
/* function BasicCard({
  showLoader,
  adjustRef = () => undefined,
  handleClick = () => undefined,
  children,
  className = "",
  customHeight,
  open = false,
  ...rest
}) {
  return (
    <div
      ref={adjustRef}
      style={{ maxHeight: `${customHeight ? customHeight : "330px"}` }}
      className={`${s.custom_select} ${open ? s.active : ""} ${className}`}
      {...rest}
    >
      <Card onClick={handleClick}>{showLoader ? <Loader /> : children}</Card>
    </div>
  );
} */
class BasicCard extends React.Component {

  state = {
    containerHeight: 0
  }

  _container = null;
  _receivedFocus = false

  adjustRef = el => {
    this._container = el;
    if (this.props.adjustRef) {
      this.props.adjustRef(el);
    }
  }

  componentDidMount() {
    this.computeHeight();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this._receivedFocus = false;
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.children !== nextProps.children) {
      this.computeHeight();
    }
  }

  computeHeight() {
    if (this._container) {
      this.setState({
        containerHeight: this._container.offsetHeight
      })
    }
  }

  focusInnerElements = (ev) => {
      let key = ev.keyCode || ev.which;
      const TAB_CODE = 9;
      ev.preventDefault();
      if (!key) {
        if (this._receivedFocus) {
          ev.currentTarget.querySelector("li")?.focus?.();
          return;
        }
        this._receivedFocus = true;
      }
      const ARROW_BOTTOM_CODE = 40;
      const ARROW_TOP_CODE = 38;
      const { activeElement } = document;

      let li = ev.currentTarget.querySelector("li");
      if (activeElement && activeElement.tagName === "LI") {
        switch (key) {
          case ARROW_TOP_CODE: {
            li = activeElement.previousSibling?.closest?.("li");
            if (!li) {
              this.props.focusInput ? this.props.focusInput() : li = ev.currentTarget.querySelector("li:last-child");
            }
            break;
          } case TAB_CODE:
            case ARROW_BOTTOM_CODE: {
            const next = activeElement.nextSibling?.closest?.("li");
            if (next) {
              li = next;
            }
            break;
          } default: {
            li = activeElement;
            break;
          }
        }
      }
      if (li) {
        li.focus();
      }
  }

  render() {
    const {
      showLoader,
      handleClick = () => undefined,
      children,
      className = "",
      customHeight,
      open = false,
      ...rest
    } = this.props;

    return (
      <div
        ref={this.adjustRef}
        onFocus={this.focusInnerElements}
        onKeyDown={this.focusInnerElements}
        onMouseDown={ev => {
          ev.preventDefault();

          this._receivedFocus = true;
        }}
        tabIndex="1"
        style={{ maxHeight: `${customHeight ? customHeight : "330px"}`, minWidth: '210px' }}
        className={`${s.custom_select} ${open ? s.active : ""} ${className}`}
        {...rest}
      >
        <Card onClick={handleClick}>{showLoader ? <Loader style={{ height: this.state.containerHeight + 'px' }} /> : children}</Card>
      </div>
    );
  }
}

export default withStyles(s)(BasicCard);
