import React, { useState , useMemo, useRef }  from 'react';
import ActionSearch from 'material-ui/svg-icons/action/search';
import IconButton from 'material-ui/IconButton';
import styled from 'styled-components';
import cx from 'classnames'

import Search from '../Search';
import ChipsAutocomplete from '../Tags/ChipsAutocomplete';

export default function DynamicSearch({minScreenWidthToShowFullSearch = 0, transformOrigin = 'right' , ...props}) {
  const [expanded, setIsExpanded] = useState(false)
  const searchRef = useRef(null)

  const SearchComponent = useMemo(() => 
    props.name === 'tags' ? <ChipsAutocomplete setInputRef={el => searchRef.current = el} {...props} /> : <Search setInputRef={el => searchRef.current = el} {...props} />, 
    [props]
  );

  const isSearchFilled = props.name === 'tags' ? props.tags?.condition[0] && props.tags?.condition[0].anyOf?.length > 0 : props.query?.length > 0;
  console.log(searchRef?.current);
  return (
    <Wrapper transformOrigin={transformOrigin} minScreenWidthToShowFullSearch={minScreenWidthToShowFullSearch}>
      <IconButton className='small-search-btn' style={{
          width: 24,
          height: 24,
          padding: 0,
          borderRadius: '50%',
          background: isSearchFilled ? '#285596' : '#E6E6E6'
          }} onClick={()=>{
            setIsExpanded(true)
            setTimeout(()=>{
              searchRef?.current?.focus()
              searchRef?.current?.click();
            },300) 
            }} >
            <ActionSearch color={isSearchFilled ? "#fff" : '#787878'} />
      </IconButton>
      <div className={cx('search-container', expanded && 'expanded')}>
        <IconButton className='button-back' style={{
          width: 24,
          height: 24,
          padding: 0,
        }}>
          <span onClick={()=>setIsExpanded(false)} className={cx("material-icons-outlined","button-back-icon")}>
            arrow_back
          </span>
        </IconButton>
        {SearchComponent}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin: 0 6px;
  width: 100%;
  align-items: center;
  .small-search-btn {
    display: none !important;
  }
  .small-search-btn svg{
    width: 18px !important;
    height: 18px !important;
    transform: scaleX(-1) !important;
  }
  .search-container{
    display: flex;
    width: 100%;
  }
  .button-back{
    display: none !important;
  }
  .button-back-icon{
    font-size: 24px;
    color: #999;
  }
  @media (max-width: ${props => props.minScreenWidthToShowFullSearch}px){
    .small-search-btn {
      display: inline-block !important;
    }
    .search-container{
      position: absolute;
      ${props => props.transformOrigin}: 0;
      top: 50%;
      transform:translateY(-50%) scaleX(0);
      transform-origin: ${props => props.transformOrigin};
      z-index: 1;
      align-items: center;
      gap: 4px;
      padding: 0 8px;
      display: flex;
      visibility: hidden;
      width: fit-content;
      transition: transform 0.3s, visibility 0.3s;
    }
    .search-container.expanded {
      height: 48px;
      background: #f5f5f5;
      visibility: visible;
      transform:translateY(-50%) scaleX(1);
      box-shadow: ${props => props.transformOrigin === 'left' ? '8px' : '-8px'} 0 8px rgba(245, 245, 245, 0.5);
    }
    .button-back {
      display: inline-block !important;
    }
  }
`

